import { showNotification } from "@mantine/notifications";
import axios from "axios";
import api from "../../../api/api";
import getToken from "../../../utils/jwt-token";

export const downloadCSVFile = async ({
  value,
  filter,
  filterValue,
  includeShippingInfo,
  prizeFilter,
}) => {
  try {
    showNotification({
      id: "fetch-off-chain",
      title: "Fetching Data!",
      message: "Generating your CSV file...",
      color: "green",
      disallowClose: false,
    });
    const csv = await api
      .post({
        endpoint: `/auth/download/csv/${value}`,
        data: {
          filter,
          filterValue,
          includeShippingInfo,
          prizeFilter,
        },
      })
      .then((response) => response?.data);
    if (csv) {
      const hiddenDownloadLink = document.createElement("a");
      hiddenDownloadLink.href = `data:text/csv;charset=utf-8,${encodeURI(
        csv
      ).replaceAll("#", "%23")}`;
      hiddenDownloadLink.target = "_blank";
      hiddenDownloadLink.download = `${value} Spins.csv`;
      hiddenDownloadLink.click();
    }
  } catch (e) {
    console.log(e);
    showNotification({
      id: "fetch-off-chain-error",
      title: "Cannot get CSV data",
      message: "Please try again later",
      color: "red",
      disallowClose: false,
    });
  }
};

export const uploadCSVFile = async (getSpins) => {
  try {
    showNotification({
      id: "upload-file",
      title: "Upload a File",
      message: "Please upload a file that is correctly formatted ",
      color: "green",
      disallowClose: false,
    });
    const hiddenUploadInput = document.createElement("input");
    hiddenUploadInput.type = "file";
    hiddenUploadInput.click();
    const formData = new FormData();
    hiddenUploadInput.addEventListener("change", async (event) => {
      const fileList = event.target.files;
      formData.append("file", fileList[0]);

      if (formData) {
        const token = getToken("auth/upload/csv/orders", {
          data: {
            formData,
          },
        });
        const url = `${process.env.REACT_APP_API_URL}/auth/upload/csv/orders`;
        await axios
          .post(url, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              token,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              showNotification({
                id: "upload-success",
                title: "Successfully Updated!",
                message:
                  "Your CSV file has successfully been uploaded & all edited Spins have been updated",
                color: "green",
                disallowClose: false,
              });
              getSpins();
            }
          });
      }
    });
  } catch (e) {
    console.log(e);
    showNotification({
      id: "upload-error",
      title: "Cannot upload CSV data",
      message: "Please try again later",
      color: "red",
      disallowClose: false,
    });
  }
};
