import React from "react";
import { Title, Card, Image, Text, Badge, Group } from "@mantine/core";
import { RichText } from "prismic-reactjs";

function PrizeCard({ prize }) {
  const {
    image: { url },
    name,
    description,
    type,
  } = prize;

  return (
    <div size="1400px" key={name[0].text}>
      <Card
        className="min-h-[400px] h-full"
        withBorder
        shadow="sm"
        p="lg"
        radius="md"
      >
        <Card.Section className="bg-slate-100">
          <Image height={200} src={url} alt="prizeImage" />
        </Card.Section>

        <Group
          spacing={0}
          direction="column"
          align="center"
          className="flex-nowrap py-2"
        >
          <Title order={5} className="m-1 font-semibold">
            {name}
          </Title>
          <Badge color="blue" variant="light">
            {type}
          </Badge>
        </Group>

        <Text size="md" className="text-center">
          {description ? (
            <RichText render={description} />
          ) : (
            `The prize you will win for this item is ${name}.`
          )}
        </Text>
      </Card>
    </div>
  );
}

export default PrizeCard;
