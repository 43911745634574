/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import {
  Anchor,
  Space,
  Text,
  Modal,
  Title,
  Stack,
  Button,
  Accordion,
  TextInput,
  Group,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import api from "../../../../api/api";
import { StoreContext } from "../../../../providers/StoreProvider";
import YesAndNoButtons from "../AllSpins/YesAndNoButtons";

// very similar to UpdateSpinModal but only used on 'Orders' table & limited to tracking info
function UpdateOrderModal({
  getSpins,
  activeOrderDetails,
  setActiveOrderDetails,
}) {
  const { user: adminUserDetails } = useContext(StoreContext);

  const [saving, setSaving] = useState(false);
  const [orderShippedSuccessful, setOrderShippedSuccessful] = useState(
    activeOrderDetails?.rewardsComplete
  );
  const [shippingInfo, setShippingInfo] = useState(
    activeOrderDetails?.shipping || {
      url: "",
      trackingId: "",
      carrier: "",
    }
  );

  const updateSpin = async () => {
    setSaving(true);
    try {
      await api.post({
        endpoint: "/auth/updateSpin",
        data: {
          adminUserId: adminUserDetails.id,
          id: activeOrderDetails._id,
          shipping: shippingInfo,
          rewardsComplete: orderShippedSuccessful,
        },
      });
      showNotification({
        id: "update-success",
        title: "Successfully Updated Order!",
        message: `Spin ID: ${activeOrderDetails._id}`,
        color: "green",
        disallowClose: false,
      });
      setActiveOrderDetails(undefined);
      getSpins();
    } catch (e) {
      console.log(e);
      showNotification({
        id: "update-error",
        title: "Updating Order Failed!",
        message: "Please try again later",
        color: "red",
        disallowClose: false,
      });
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    setShippingInfo(activeOrderDetails?.shipping);
    setOrderShippedSuccessful(activeOrderDetails?.rewardsComplete);
  }, [activeOrderDetails]);

  return (
    <Modal
      title={<Title order={3}>Edit Order Shipping Details</Title>}
      opened={!!activeOrderDetails}
      onClose={() => setActiveOrderDetails(undefined)}
    >
      {activeOrderDetails && (
        <>
          <Text size="md">
            Update shipping confirmation and/or tracking details for the order
            below!
          </Text>

          <Space h="md" />
          <Stack spacing="sm">
            <div>
              <Text size="sm" color="gray">
                Spin Id
              </Text>
              <Text size="sm" className="break-all">
                {activeOrderDetails?._id}
              </Text>
            </div>
            <div>
              <Text size="sm" color="gray">
                Wallet
              </Text>
              <Text size="sm" className="break-all">
                {activeOrderDetails?.user?.wallet}
              </Text>
            </div>
            <div>
              <Text size="sm" color="gray">
                Discord Id
              </Text>
              <Text size="sm" className="break-all">
                {activeOrderDetails?.user?.discordId || "-"}
              </Text>
            </div>
            <div>
              <Text size="sm" color="gray">
                Winning Prize
              </Text>
              <Text size="sm" className="break-all">
                {activeOrderDetails?.prize?.name || "-"}
              </Text>
            </div>
            <Accordion iconSize={14} multiple>
              <Accordion.Item label="Order Shipped?">
                <YesAndNoButtons
                  value={orderShippedSuccessful}
                  setValue={setOrderShippedSuccessful}
                />
              </Accordion.Item>

              <Accordion.Item label="Shipping Information">
                <Group position="apart" grow>
                  <TextInput
                    value={shippingInfo?.carrier}
                    onChange={(e) =>
                      setShippingInfo({
                        ...shippingInfo,
                        carrier: e.target.value,
                      })
                    }
                    placeholder="Carrier"
                    label="Carrier"
                  />
                  <TextInput
                    value={shippingInfo?.trackingId}
                    onChange={(e) =>
                      setShippingInfo({
                        ...shippingInfo,
                        trackingId: e.target.value,
                      })
                    }
                    placeholder="Tracking Id"
                    label="Tracking Id"
                  />
                </Group>
                <TextInput
                  className="mt-1"
                  value={shippingInfo?.url}
                  onChange={(e) =>
                    setShippingInfo({ ...shippingInfo, url: e.target.value })
                  }
                  placeholder="enter a link"
                  label="Tracking Link"
                />
              </Accordion.Item>
            </Accordion>
            <Button loading={saving} onClick={updateSpin}>
              Save
            </Button>
          </Stack>
        </>
      )}
    </Modal>
  );
}
export default UpdateOrderModal;
