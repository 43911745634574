import React, { useEffect, useState } from "react";
import {
  Alert,
  Anchor,
  Button,
  Divider,
  Group,
  Modal,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { AlertCircle, ExternalLink } from "tabler-icons-react";
import { useWallet } from "@solana/wallet-adapter-react";
import { StoreContext } from "../../../providers/StoreProvider";
import api from "../../../api/api";
import { prismicRepo } from "../../../utils/config";

export default function PrismicAuth() {
  const [opened, setOpened] = useState(false);
  const [data, setData] = useState();
  const wallet = useWallet();
  const { user } = React.useContext(StoreContext);

  useEffect(() => {
    const validatePrismic = async () => {
      try {
        await api
          .get({
            endpoint: `/auth/validate`,
          })
          .then((response) => setData(response.data));
      } catch (e) {
        console.log(e);
      }
    };
    if (user?.id && wallet?.connected) validatePrismic();
  }, [user, wallet]);

  if (!data || data.length === 0) return <></>;

  return (
    <>
      <Alert
        className="mb-5 cursor-pointer"
        icon={<AlertCircle size={16} />}
        title="Prismic error!"
        color="red"
        onClick={() => setOpened(true)}
      >
        You have errors in prismic, click here for validation information.
      </Alert>
      <Modal
        title={<Title order={3}>Review prismic errors</Title>}
        size={600}
        opened={opened}
        onClose={() => setOpened(false)}
      >
        <Stack>
          {data.map((prize) => {
            return (
              <>
                <Divider />
                <Stack>
                  <Group position="apart">
                    <Title className="text-gray-500" order={5}>
                      {prize.name}
                    </Title>
                    <Anchor
                      href={`https://${prismicRepo}.prismic.io/documents~b=working&c=published&l=en-us/${prize.id}/`}
                      target="_blank"
                      className="flex items-center gap-1"
                    >
                      {prize.id}
                      <ExternalLink />
                    </Anchor>
                  </Group>

                  <Text>{prize.error}</Text>
                </Stack>
              </>
            );
          })}

          <Button className="mt-5" onClick={() => setOpened(false)}>
            Close
          </Button>
        </Stack>
      </Modal>
    </>
  );
}
