export const filterOptions = [
  { value: "_id", label: "Spin Id" },
  { value: "user", label: "User Id" },
  { value: "rewardsComplete", label: "Shipped?" },
];

export const getColumns = (onSpinIdClick) => {
  return [
    {
      label: "Spin Id",
      id: "id",
      cols: 4,
      short: true,
      modal: true,
      onClick: onSpinIdClick,
    },
    { label: "Prize", id: "prize.name", cols: 3, short: false },
    {
      label: "Prize Id",
      id: "prize.id",
      cols: 5,
      short: true,
      withCopyTooltip: true,
    },
    {
      label: "Tracking Id",
      id: "shipping.trackingId",
      cols: 4,
      short: true,
      withCopyTooltip: true,
    },
    {
      label: "Carrier",
      id: "shipping.carrier",
      cols: 4,
      short: false,
    },
    {
      label: "Tracking Link",
      id: "shipping.url",
      cols: 4,
      short: true,
      link: true,
    },
    {
      label: "Shipped",
      id: "rewardsComplete",
      cols: 2,
      short: false,
      checkbox: true,
    },
    {
      label: "User Id",
      id: "user.id",
      cols: 5,
      short: true,
      withCopyTooltip: true,
    },
    {
      label: "Discord Id",
      id: "user.discordId",
      cols: 4,
    },
    {
      label: "Date Won",
      id: "updatedAt",
      cols: 4,
      date: true,
    },
  ];
};
