import { Group, Card, Text, Title } from "@mantine/core";
import React from "react";

export default function StatsBanner({ bannerData }) {
  return (
    <Group position="center" grow className="text-center flex h-32 lg:h-24">
      {bannerData.map((stat, i) => {
        return (
          <Card
            sx={() => ({
              // subscribe to color scheme changes
              backgroundColor: stat.backgroundColor,
              // or use any other static values from theme
              color: "white",
            })}
            className="text-center flex flex-col h-full"
            key={i}
          >
            <Title>{stat.title}</Title>
            <Text size="lg">{stat.text}</Text>
          </Card>
        );
      })}
    </Group>
  );
}
