import dayjs from "dayjs";

// eslint-disable-next-line import/prefer-default-export
export const shortAddress = (address) => {
  if (!address) return "";
  return `${address.slice(0, 6)}...${address.slice(-6)}`;
};

export async function copyTextToClipboard(text) {
  if ("clipboard" in navigator) {
    // eslint-disable-next-line no-return-await
    return await navigator.clipboard.writeText(text);
  }
  return document.execCommand("copy", true, text);
}

export function formatTime(date) {
  return dayjs(new Date(date)).format("MM/DD hh:mm a");
}
