import React, { useState, useEffect } from "react";
import { Center, Modal, Title, Text, Button } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { StoreContext } from "../../../providers/StoreProvider";

function UnderMaintenanceModal() {
  const [modalOpen, setModalOpen] = useState(false);
  const { underMaintenance } = React.useContext(StoreContext);

  useEffect(() => {
    setModalOpen(underMaintenance);
  }, [underMaintenance]);

  if (underMaintenance) {
    showNotification({
      id: "under-maintenance",
      title: "SITE UNDER MAINTENANCE",
      message: "Purchasing credits & spinning have been disabled. ",
      color: "red",
      disallowClose: true,
      autoClose: false,
    });
  }

  return (
    <Modal
      title={
        <Title order={3}>Uhh oh, site is currently under maintenance!!!</Title>
      }
      size={600}
      opened={modalOpen}
      onClose={() => setModalOpen(false)}
    >
      <Text>
        Site admins are currently busy updating the spinner. As a result,
        purchasing credits and spinning have been temporarily disabled.
      </Text>
      <Center className="mt-4">
        <Button color="red" onClick={() => setModalOpen(false)}>
          Close
        </Button>
      </Center>
    </Modal>
  );
}
export default UnderMaintenanceModal;
