import React, { useMemo, useCallback, useState, useEffect } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { PublicKey, Transaction } from "@solana/web3.js";
import { createTransferInstruction, TOKEN_PROGRAM_ID } from "@solana/spl-token";

import {
  showNotification,
  updateNotification,
  cleanNotifications,
} from "@mantine/notifications";
import { LoadingOverlay } from "@mantine/core";
import api from "../api/api";

const messages = {
  user: {
    error: {
      title: "User not found",
      message: "Please refresh your screen or try re-connecting your wallet",
      color: "red",
      disallowClose: false,
    },
  },
  wallet: {
    error: {
      title: "Wallet not connected",
      message: "Connect your wallet to spin the wheel",
      color: "red",
      disallowClose: false,
    },
  },
  notifications: {
    burn: {
      start: {
        title: "Sending tokens...",
        message: `Sending your $${process.env.REACT_APP_TOKEN_SYMBOL} please wait`,
        color: "green",
        loading: true,
        autoClose: false,
      },
      success: {
        autoClose: 3000,
        title: "Sending complete!",
        color: "green",
      },
      error: {
        title: "Error! Unable to send",
        message: "There was an error sending your tokens",
        color: "red",
        disallowClose: false,
      },
    },
    send: {
      start: {
        title: "Sending tokens...",
        message: `Sending your ${process.env.REACT_APP_TOKEN_SYMBOL} please wait`,
        color: "green",
        loading: true,
        autoClose: false,
      },
      success: {
        autoClose: 3000,
        title: "Tokens sent!",
        color: "green",
      },
      noPrize: {
        autoClose: 5000,
        title: "Error! No prize allocated",
        message: "You have been awarded a spin credit",
        color: "red",
        disallowClose: false,
      },
      error: {
        title: "Error! Unable to send tokens",
        message: "There was an error processing your transaction",
        color: "red",
        disallowClose: false,
      },
    },
    burnNft: {
      start: {
        title: "Sending...",
        message: `Sending your NFT please wait`,
        color: "green",
        loading: true,
        autoClose: false,
      },
      success: {
        autoClose: 3000,
        title: "NFT sent!",
        color: "green",
      },
      noPrize: {
        autoClose: 5000,
        title: "Error! No prize allocated",
        message: "You have been awarded a spin credit",
        color: "red",
        disallowClose: false,
      },
      error: {
        title: "Error! Unable to send NFT",
        message: "There was an error processing your transaction",
        color: "red",
        disallowClose: false,
      },
    },
    sendPrize: {
      start: {
        title: "Sending prize...",
        message: "Please wait whilst we distribute your prize.",
        color: "green",
        loading: true,
        disallowClose: true,
        autoClose: false,
      },
      success: {
        autoClose: 3000,
        title: "Success! Prize sent",
        message: "Your prize has been successfully processed",
        color: "green",
      },
    },
    spinCredits: {
      start: {
        title: "Buying spins...",
        message: `Sending your $${process.env.REACT_APP_TOKEN_SYMBOL} please wait`,
        color: "green",
        loading: true,
        disallowClose: true,
        autoClose: false,
      },
      update: {
        title: "Sending transactions...",
        message:
          "Please wait whilst we are sending your tokens and confirming the transactions.",
        color: "green",
        loading: true,
        disallowClose: true,
        autoClose: false,
      },
      success: {
        autoClose: 3000,
        title: "Success!",
        message: "Your credits have been purchased",
        color: "green",
      },
      error: {
        title: "Error! Unable to purchase credits",
        message: "There was an error processing your transaction.",
        color: "red",
        disallowClose: false,
      },
    },
  },
};

export const StoreContext = React.createContext();

function StoreProvider({ children }) {
  const wallet = useWallet();
  const [underMaintenance, setUnderMaintenance] = useState(false);
  const [prizes, setPrizes] = useState([]);
  const [user, setUser] = useState();
  const [users, setUsers] = useState();
  const [userSpins, setUserSpins] = useState();
  const [leaderboard, setLeaderboard] = useState();
  const [prizeTypes, setPrizeTypes] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [prizeTypeMap, setPrizeTypeMap] = useState();
  const [loading, setLoading] = useState(false);
  const [credits, setCredits] = useState(0);
  const [spinnerInfo, setSpinnerInfo] = useState();

  const createTransation = useCallback(
    async (sourceAddress, destinationPubkey, amount) => {
      const transaction = new Transaction().add(
        createTransferInstruction(
          sourceAddress,
          destinationPubkey,
          wallet.publicKey,
          amount,
          [],
          TOKEN_PROGRAM_ID
        )
      );

      transaction.feePayer = wallet.publicKey;
      return transaction;
    },
    [wallet?.publicKey, wallet?.publicKey]
  );

  // const spinWheel = async () => {
  //   if (!user.id || !wallet.connected) {
  //     const title = wallet.connected
  //       ? "User not found"
  //       : "Wallet not connected";
  //     const message = wallet.connected
  //       ? "Please refresh your screen or try re-connecting your wallet"
  //       : "Connect your wallet to spin the wheel";
  //     showNotification({
  //       id: "burn-error",
  //       title,
  //       message,
  //       color: "red",
  //       disallowClose: false,
  //     });
  //   }
  //   showNotification({
  //     id: "burn",
  //     title: "Burning tokens...",
  //     message: "Burning your $TOKEN please wait",
  //     color: "green",
  //     loading: true,
  //     autoClose: false,
  //   });
  //   // get burn instructions
  //   const result = await api
  //     .get({
  //       endpoint: `/spins/burn/${wallet.publicKey.toString()}`,
  //     })
  //     .then((response) => response?.data);

  //   if (result) {
  //     // create burn instructions
  //     try {
  //       const instructions = createBurnInstruction(
  //         new PublicKey(result.instructions.tokenAccountPubkey),
  //         new PublicKey(result.instructions.tokenMintPubkey),
  //         wallet.publicKey,
  //         result.instructions.amount
  //       );
  //       const transaction = new Transaction(result.tx).add(instructions);
  //       transaction.recentBlockhash = result.recentBlockhash;
  //       transaction.setSigners(...[wallet.publicKey]);
  //       // prompts the wallet provider (phantom) to open
  //       const signed = await wallet.signTransaction(transaction);

  //       const spin = await api
  //         .post({
  //           endpoint: `/spins/burn/${wallet.publicKey.toString()}`,
  //           data: {
  //             signed: signed.serialize(),
  //           },
  //         })
  //         .then((response) => response.data);
  //       updateNotification({
  //         id: "burn",
  //         autoClose: 3000,
  //         title: "Burning complete!",
  //         color: "green",
  //       });
  //       return spin;
  //     } catch (error) {
  //       cleanNotifications();
  //       showNotification({
  //         id: "buy-spins",
  //         title: "Error! Unable to burn",
  //         message: error.message,
  //         color: "red",
  //         disallowClose: false,
  //       });
  //     }
  //   }
  // };

  const getSpins = async () => {
    if (!user?.id) return;
    await api
      .get({
        endpoint: `/spins/credits/${user.id}`,
      })
      .then((response) => setCredits(response?.data || 0));
  };

  const spinWheel = async () => {
    if (!user.id || !wallet.connected) {
      showNotification({
        id: "send",
        title: wallet.connected
          ? messages.user.error.title
          : messages.wallet.error.title,
        message: wallet.connected
          ? messages.user.error.message
          : messages.wallet.error.message,
        color: "red",
        disallowClose: false,
      });
    }

    const balance = await api.get({
      endpoint: `/users/check-balance/${wallet.publicKey?.toBase58()}`,
    });
    if (balance?.data.amount < balance?.data.spinPrice) {
      showNotification({
        id: "send",
        title: `Not enough $${process.env.REACT_APP_TOKEN_SYMBOL}`,
        color: "red",
        disallowClose: false,
      });
      return undefined;
    }
    if (balance?.data.sol < 0.0001) {
      showNotification({
        id: "send",
        title: `Not enough SOL for transaction`,
        color: "red",
        disallowClose: false,
      });
      return undefined;
    }

    showNotification({
      id: "send",
      ...messages.notifications.send.start,
    });
    // get burn instructions
    const result = await api
      .get({
        endpoint: `/spins/send/${wallet.publicKey.toString()}`,
      })
      .then((response) => response?.data);

    if (result) {
      // create burn instructions
      try {
        const transaction = await createTransation(
          new PublicKey(result.instructions.sourceAddress),
          new PublicKey(result.instructions.destinationPubkey),
          result.instructions.amount
        );
        transaction.recentBlockhash = result.recentBlockhash;

        const signed = await wallet.signTransaction(transaction);

        const spin = await api
          .post({
            endpoint: `/spins/transaction/${wallet.publicKey.toString()}`,
            data: {
              signed: signed.serialize(),
            },
          })
          .then((response) => response.data);
        const notification = spin.prize
          ? messages.notifications.send.success
          : messages.notifications.send.noPrize;
        updateNotification({
          id: "send",
          ...notification,
        });
        if (!spin.prize) getSpins();
        return spin;
      } catch (error) {
        updateNotification({
          id: "send",
          ...messages.notifications.send.error,
          message: error.message,
        });
      }
    }
  };
  const sendPrize = async (id) => {
    try {
      showNotification({
        id: "prize",
        ...messages.notifications.sendPrize.start,
      });

      const result = await api.get({
        endpoint: `/spins/send-prize/${id}`,
      });

      updateNotification({
        id: "prize",
        ...messages.notifications.sendPrize.success,
      });
      return result?.data;
    } catch (e) {
      cleanNotifications();
    }
  };

  const getSpinnerPrizes = useCallback(async () => {
    await api
      .get({
        endpoint: "/prismic/spinner-prizes",
      })
      .then((result) => {
        setUnderMaintenance(result.data.underMaintenance);
        setPrizes(result.data.prizes);
      });
    setLoading(false);
  }, []);

  useEffect(() => {
    setLoading(true);
    getSpinnerPrizes();
  }, [getSpinnerPrizes]);

  const getOrCreateUser = async () => {
    await api
      .get({
        endpoint: `/users/wallet/${wallet.publicKey.toString()}`,
      })
      .then((response) => setUser(response?.data));
  };

  const getSpinnerInfo = async () => {
    await api
      .get({
        endpoint: `/prismic/spinner-info`,
      })
      .then((response) => setSpinnerInfo(response?.data));
  };

  useEffect(() => {
    if (wallet?.publicKey) getOrCreateUser();
  }, [wallet]);

  const getUserSpins = async (skip, type) => {
    if (user?.id) {
      await api
        .get({
          endpoint: `/spins/user/${user.id}/${skip || 0}/${type || "all"}`,
        })
        .then((response) => {
          setUserSpins(response?.data || {});
        });
    }
  };

  const getLeaderboard = async () => {
    await api
      .get({
        endpoint: `/winners/leaderboard/spins`,
      })
      .then((response) => setLeaderboard(response?.data || {}));
  };

  const signMultipleTransactions = async (amount) => {
    const balance = await api.get({
      endpoint: `/users/check-balance/${wallet.publicKey?.toBase58()}`,
    });
    if (balance?.data.amount < (balance?.data.spinPrice || 0) * amount) {
      showNotification({
        id: "send",
        title: `Not enough $${process.env.REACT_APP_TOKEN_SYMBOL}`,
        color: "red",
        disallowClose: false,
      });
      return undefined;
    }
    if (balance?.data.sol < 0.0001) {
      showNotification({
        id: "send",
        title: `Not enough SOL for transaction`,
        color: "red",
        disallowClose: false,
      });
      return undefined;
    }

    showNotification({
      id: "buy-spins",
      ...messages.notifications.spinCredits.start,
    });

    const result = await api
      .get({
        endpoint: `/spins/send/${wallet.publicKey.toString()}`,
      })
      .then((response) => response?.data);

    if (result) {
      // create burn instructions
      // eslint-disable-next-line no-plusplus
      try {
        const transaction = await createTransation(
          new PublicKey(result.instructions.sourceAddress),
          new PublicKey(result.instructions.destinationPubkey),
          result.instructions.amount * amount
        );
        transaction.recentBlockhash = result.recentBlockhash;
        const txn = await wallet.signTransaction(transaction);
        updateNotification({
          id: "buy-spins",
          ...messages.notifications.spinCredits.update,
        });
        await api
          .post({
            endpoint: `/spins/credits/${wallet.publicKey.toString()}`,
            data: {
              signed: txn.serialize(),
              amount,
            },
          })
          .then((response) => response.data);

        getSpins();
        updateNotification({
          id: "buy-spins",
          ...messages.notifications.spinCredits.success,
        });
        return true;
      } catch (error) {
        console.log(error);
        updateNotification({
          id: "buy-spins",
          ...messages.notifications.spinCredits.error,
          message: error.message,
        });
        return false;
      }
    }
  };

  const spinWithCredit = async () => {
    const result = await api
      .get({
        endpoint: `/spins/use-credit/${user.id}`,
      })
      .then((response) => response?.data);
    await getSpins();
    return result;
  };

  useEffect(() => {
    if (user) {
      getUserSpins();
      getSpins();
    }
  }, [user]);

  // not being used right now, can probably remove later...replaced with getPrizeTypeMap
  const getPrizeTypes = async () => {
    await api
      .get({
        endpoint: `/spins/prize/types`,
      })
      .then((response) => setPrizeTypes(response?.data || {}));
  };
  const getPrizeTypeMap = async () => {
    await api
      .get({
        endpoint: `/spins/prize/type-map`,
      })
      .then((response) => setPrizeTypeMap(response?.data || {}));
  };

  const getUsers = async (skip, filter, filterValue) => {
    if (filterValue && filter && filterValue.length < 24) return;
    const result = await api
      .post({
        endpoint: `/auth/spin-credits/${skip || 0}`,
        data: {
          filter,
          filterValue,
        },
      })
      .then((response) => response?.data);
    setUsers(result);
  };

  const getUserCollectionNfts = useCallback(async () => {
    if (!wallet?.publicKey) return undefined;
    const result = await api.get({
      endpoint: `/spins/collection-nfts/${wallet.publicKey.toBase58()}`,
    });
    return result?.data || [];
  }, [wallet?.publicKey]);

  useEffect(() => {
    getLeaderboard();
    getPrizeTypes();
    getPrizeTypeMap();
    getSpinnerInfo();
  }, []);

  const burnNftToSpin = useCallback(async () => {
    try {
      if (!wallet?.publicKey) return undefined;
      showNotification({
        id: "send",
        ...messages.notifications.burnNft.start,
      });
      const result = await api
        .get({
          endpoint: `/spins/tx/burn/nft/${wallet.publicKey.toBase58()}`,
          responseType: "application/octet-stream",
        })
        .then((response) => response?.data);
      if (result) {
        const tx = Transaction.from(Buffer.from(result));
        tx.feePayer = wallet.publicKey;
        const signed = await wallet.signTransaction(tx);
        await api
          .post({
            endpoint: `/spins/credits/${wallet.publicKey.toString()}`,
            data: {
              signed: signed.serialize(),
              amount: 25,
            },
          })
          .then((response) => response.data);

        getSpins();
        const notification = messages.notifications.spinCredits.success;

        updateNotification({
          id: "send",
          ...notification,
        });
        // if (!spin.prize) getSpins();
        // return spin;
      }
      return undefined;
    } catch (error) {
      console.log(error);
      updateNotification({
        id: "send",
        ...messages.notifications.burnNft.error,
        message: error.message,
      });
      return undefined;
    }
  }, [wallet]);

  const contextValue = useMemo(
    () => ({
      user,
      userSpins,
      leaderboard,
      prizeTypes,
      prizeTypeMap,
      credits,
      users,
      getSpins,
      getUsers,
      signMultipleTransactions,
      spinWithCredit,
      setUser,
      sendPrize,
      getUserSpins,
      getOrCreateUser,
      spinWheel,
      prizes,
      underMaintenance,
      spinnerInfo,
      getUserCollectionNfts,
      burnNftToSpin,
    }),
    [
      user,
      userSpins,
      credits,
      users,
      getUsers,
      getUserSpins,
      getOrCreateUser,
      spinWithCredit,
      spinWheel,
      getSpins,
      prizes,
      underMaintenance,
      signMultipleTransactions,
      spinnerInfo,
      getUserCollectionNfts,
      burnNftToSpin,
    ]
  );

  return (
    <StoreContext.Provider value={contextValue}>
      <LoadingOverlay visible={loading} />
      {children}
    </StoreContext.Provider>
  );
}

export default StoreProvider;
