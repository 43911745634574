/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import { Modal, Button, Title, Space } from "@mantine/core";
import { RichText } from "prismic-reactjs";
import api from "../../api/api";

function InfoModal({ opened, setOpened }) {
  const [data, setData] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const result = await api
        .get({
          endpoint: "/prismic/spinner-modal",
        })
        .then((response) => response.data);
      setData(result);
    };
    getData();
  }, []);
  if (!data) return <></>;

  const { title, description, button_label } = data.data;

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={
          <Title order={1} transform="uppercase">
            {title}
          </Title>
        }
        size={600}
      >
        <div>
          <RichText render={description} />
          <Space h="lg" />
          <Button onClick={() => setOpened(false)} style={{ width: "100%" }}>
            {button_label}
          </Button>
        </div>
      </Modal>
    </>
  );
}
export default InfoModal;
