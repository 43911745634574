/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Text,
  List,
  Space,
  Group,
  Select,
  Title,
  Pagination,
  Button,
  Skeleton,
  TextInput,
  Tooltip,
} from "@mantine/core";
import api from "../../../../api/api";
import YesAndNoButtons from "../AllSpins/YesAndNoButtons";
import UpdateOrderModal from "./UpdateOrderModal";
import { downloadCSVFile, uploadCSVFile } from "../../utils/csv";
import LineItems from "../../../../components/LineItems";
import { getColumns, filterOptions } from "./config";

function Orders() {
  const [orders, setOrders] = useState([]);
  const [prizeTypes, setPrizeTypes] = useState([]);
  // total number of spins by all users
  const [total, setTotal] = useState(0);
  // control pagination state
  const [activePage, setPage] = useState(1);
  // control dropdown filter state
  const [filter, setFilter] = useState();
  // control Prize Type dropdown filter state
  const [prizeFilter, setPrizeFilter] = useState();
  // control checkbox filter state
  const [filterValue, setFilterValue] = useState(false);
  // selected order to update
  const [activeOrderDetails, setActiveOrderDetails] = useState();
  const [loading, setLoading] = useState(false);
  const ref = useRef();

  const onSpinIdClick = (spin) => setActiveOrderDetails(spin);
  const columns = getColumns(onSpinIdClick);

  const getSpins = useCallback(
    async (skip) => {
      setLoading(true);
      if (filterValue && filter && filterValue?.length < 24) {
        return;
      }
      const result = await api
        .post({
          endpoint: `/auth/orders/${prizeFilter}/${skip || 0}`,
          data: {
            filter,
            filterValue,
          },
        })
        .then((response) => response?.data);
      setOrders(result?.orders);
      setTotal(result?.count || 0);
      setPrizeTypes(result?.prizeNames);
      setLoading(false);
    },
    [filter, filterValue, prizeFilter]
  );

  useEffect(() => {
    if (filter === "rewardsComplete") {
      setFilterValue(false);
    } else {
      setFilterValue(undefined);
      ref.current.value = "";
    }
  }, [filter]);

  useEffect(() => {
    getSpins();
  }, [getSpins]);

  const paginate = (page) => {
    setPage(page);
    getSpins((page - 1) * 30);
  };

  return (
    <div>
      <Title className="mb-3">
        Use this page to batch update details for Off-Chain prizes
      </Title>
      <Text size="lg" weight={700}>
        Follow these instructions to best leverage this tool's functionality!
      </Text>
      <List type="ordered" size="lg" className="mb-3">
        <List.Item>
          Download CSV file with desired dataset. A prize type{" "}
          <b> must be selected </b> using the 'Prize' Dropdown below if you wish
          to utilize the CSV features
        </List.Item>
        <List.Item>Update columns with Shipping Information</List.Item>
        <List size="lg" withPadding>
          <List.Item>Carrier</List.Item>
          <List.Item>Tracking ID</List.Item>
          <List.Item>Tracking Info Link</List.Item>
        </List>
        <List.Item>
          Once all Spins that you would like to update have successfully been
          updated in the CSV file, save the file and close.
        </List.Item>
        <List.Item>
          Finally, click the button below to upload the CSV file and batch
          update all changes made.
        </List.Item>
      </List>
      <Group position="apart" align="end">
        <Group align="center">
          <Select
            data={prizeTypes}
            value={prizeFilter}
            onChange={setPrizeFilter}
            clearable
            required
            label="Prize"
          />
          <Select
            data={filterOptions}
            value={filter}
            onChange={setFilter}
            clearable
            label="Filter"
          />
          {filter !== "rewardsComplete" ? (
            <TextInput
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
              disabled={!filter}
              placeholder="Must be exact"
              label="Filter Value"
              ref={ref}
              required
            />
          ) : (
            <div className="self-end">
              <YesAndNoButtons value={filterValue} setValue={setFilterValue} />
            </div>
          )}
        </Group>
        <Group>
          <Tooltip
            label="Select a Prize Type to use this feature"
            width="135px"
            withArrow
            wrapLines
            disabled={prizeFilter}
          >
            <Button
              onClick={() => uploadCSVFile(getSpins)}
              disabled={!prizeFilter}
            >
              Upload CSV
            </Button>
          </Tooltip>
          <Tooltip
            label="Select a Prize Type to use this feature"
            width="135px"
            withArrow
            wrapLines
            disabled={prizeFilter}
          >
            <Button
              onClick={() =>
                downloadCSVFile({
                  value: "orders",
                  filter,
                  filterValue,
                  includeShippingInfo: true,
                  prizeFilter,
                })
              }
              disabled={!prizeFilter}
            >
              Download CSV
            </Button>
          </Tooltip>
          <Title order={3}>{total} Results</Title>
        </Group>
      </Group>
      <Space h="lg" />
      {loading && Array(30).fill(<Skeleton height="97.88px" />)}
      {!loading && (
        <div>
          <LineItems columns={columns} items={orders} />
        </div>
      )}
      <Space h="md" />
      <Pagination
        position="right"
        age={activePage}
        onChange={paginate}
        total={Math.ceil(total / 30)}
      />
      <UpdateOrderModal
        getSpins={getSpins}
        setActiveOrderDetails={setActiveOrderDetails}
        activeOrderDetails={activeOrderDetails}
      />
    </div>
  );
}
export default Orders;
