import React from "react";
import { RichText } from "prismic-reactjs";
import { StoreContext } from "../../../providers/StoreProvider";

export default function Info() {
  const { spinnerInfo } = React.useContext(StoreContext);
  return (
    <div>
      <RichText render={spinnerInfo.data.description} />
    </div>
  );
}
