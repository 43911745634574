import React, { useState } from "react";
import {
  Button,
  Modal,
  NumberInput,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import api from "../../../../api/api";
import { StoreContext } from "../../../../providers/StoreProvider";

export default function UserCreditModal({ selectedUser, setSelectedUser }) {
  const [creditAmount, setCreditAmount] = useState(0);
  const [creditTransactionId, setCreditTransactionId] = useState("");
  const [loading, setLoading] = useState(false);
  const { getUsers, user } = React.useContext(StoreContext);

  const addCredit = async () => {
    setLoading(true);
    await api
      .post({
        endpoint: `/auth/spin/credits/${selectedUser._id}`,
        user: user._id,
        data: {
          txid: creditTransactionId,
          amount: creditAmount,
        },
      })
      .then(() => getUsers(0));
    setLoading(false);
    setSelectedUser(undefined);
    setCreditAmount(0);
    setCreditTransactionId("");
  };

  return (
    <Modal
      opened={!!selectedUser}
      onClose={() => setSelectedUser(undefined)}
      title={<Title order={2}>Add spin credit</Title>}
    >
      <Stack>
        <Title order={4}>{selectedUser?.discordId || selectedUser?._id}</Title>
        <Text>
          This user currently has <b>{selectedUser?.credits?.length || 0}</b>{" "}
          credits.
        </Text>
        <Text>
          To allocate a credit for a user you need to provide a valid
          transaction id that has not been used before.
        </Text>
        <Text size="xs">
          * The purpose of this feature is to allocate spin credits when a
          user&lsquo;s transaction was not recorded for a spin due to a
          technical error.
        </Text>
        <NumberInput
          value={creditAmount}
          onChange={(val) => setCreditAmount(val)}
          label="Number of credits"
        />
        <TextInput
          value={creditTransactionId}
          onChange={(event) =>
            setCreditTransactionId(event.currentTarget.value)
          }
          label="Transaction id"
        />
        <Button
          disabled={!creditAmount || loading}
          onClick={addCredit}
          loading={loading}
        >
          Add credit(s)
        </Button>
      </Stack>
    </Modal>
  );
}
