import {
  Anchor,
  Container,
  Grid,
  Stack,
  Text,
  Tooltip,
  Center,
  Button,
  Skeleton,
} from "@mantine/core";
import { useClipboard } from "@mantine/hooks";

import React, { useEffect, useState } from "react";
import api from "../../../../api/api";
import { shortAddress } from "../../../../utils/utils";
import useWindowSize from "../../../../utils/hooks/useWindowSize";

function Admins() {
  const clipboard = useClipboard({ timeout: 500 });
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState();
  const { mobile } = useWindowSize();
  useEffect(() => {
    const getAdmins = async () => {
      setLoading(true);
      await api
        .get({
          endpoint: "/auth/admins",
        })
        .then((response) => setAdmins(response.data));
      setLoading(false);
    };
    getAdmins();
  }, []);
  const cols = mobile ? 2 : 11;

  if (!admins) return <></>;

  return (
    <Container size={1500} px={20}>
      <Stack>
        {loading &&
          admins.length === 0 &&
          Array(5).fill(<Skeleton height="30px" />)}
        {admins.map((user) => {
          return (
            <Grid columns={cols} gutter="sm" key={user.id}>
              <Grid.Col span={mobile ? 1 : 4}>
                <Text size="sm" color="gray">
                  Wallet
                </Text>
                <Text size="ms">{shortAddress(user.wallet)}</Text>
              </Grid.Col>
              <Grid.Col span={mobile ? 1 : 3}>
                <Text size="sm" color="gray">
                  User Id
                </Text>
                <Tooltip
                  label={
                    <Center>
                      {clipboard.copied ? "Copied!" : "Click to Copy"}
                    </Center>
                  }
                  width="95px"
                  withArrow
                >
                  <Button
                    variant="outline"
                    compact
                    type="button"
                    onClick={() => clipboard.copy(user.id)}
                  >
                    <Text size="ms">{shortAddress(user.id)}</Text>
                  </Button>
                </Tooltip>
              </Grid.Col>
              <Grid.Col span={mobile ? 1 : 2}>
                <Text size="sm" color="gray">
                  Discord Id
                </Text>
                <Text size="ms">{user.discordId || "-"}</Text>
              </Grid.Col>
              <Grid.Col span={mobile ? 1 : 2}>
                <Text size="sm" color="gray">
                  View Account
                </Text>
                <Anchor
                  target="_blank"
                  href={`https://solscan.io/account/${user.wallet}`}
                >
                  Solscan
                </Anchor>
              </Grid.Col>
            </Grid>
          );
        })}
      </Stack>
    </Container>
  );
}

export default Admins;
