import jsonwebtoken from "jsonwebtoken";

const getToken = (endpoint, user) => {
  const jwtSecretKey = process.env.REACT_APP_JWT_SECRET_KEY;
  const data = {
    time: new Date(),
    endpoint,
    admin: user,
  };
  const token = jsonwebtoken.sign(data, jwtSecretKey, {
    expiresIn: 30,
  });
  return token;
};

export default getToken;
