import { useMantineTheme, Skeleton } from "@mantine/core";
import React, { useEffect, useState } from "react";
import api from "../../../../api/api";
import StatsBanner from "./StatsBanner";

function Stats() {
  const [stats, setStats] = useState();
  const theme = useMantineTheme();

  useEffect(() => {
    const getStats = async () => {
      await api
        .get({
          endpoint: "/auth/spinnerStats",
        })
        .then((response) => setStats(response.data));
    };
    getStats();
  }, []);

  if (!stats) return <Skeleton height={100} />;

  const bannerData = [
    {
      title: stats.users,
      text: "Unique Wallets",
      backgroundColor: theme.colors.blue[5],
    },
    {
      title: stats.totalSpinCount,
      text: "Total Spins",
      backgroundColor: theme.colors.teal[5],
    },
    {
      title: stats.total,
      text: `Total ${process.env.REACT_APP_TOKEN_SIGN || "$"}`,
      backgroundColor: theme.colors.orange[5],
    },
  ];

  return (
    <div className="my-3 mx-auto">
      <StatsBanner bannerData={bannerData} />
    </div>
  );
}

export default Stats;
