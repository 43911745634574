/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { RichText } from "prismic-reactjs";
import {
  Group,
  Image,
  Input,
  InputWrapper,
  Modal,
  Title,
  Loader,
  Text,
  Stack,
  Button,
  Anchor,
} from "@mantine/core";
import api from "../../../api/api";
import AddressForm from "../../../components/AddressForm";
import { StoreContext } from "../../../providers/StoreProvider";
import { shortAddress } from "../../../utils/utils";
import useWindowSize from "../../../utils/hooks/useWindowSize";

function ConfirmationModal({ opened, onClose, winningPrize }) {
  const {
    prize: {
      require_address,
      require_discord_id,
      require_merch_size,
      prismicId,
      name,
    },
    _id,
  } = winningPrize || { prize: {} };
  const { prizes, user, setUser } = React.useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const [discordId, setDiscordId] = useState(user?.discordId);
  const [size, setSize] = useState(user?.size);
  const [email, setEmail] = useState(user?.email);
  const [address, setAddress] = useState({});
  const [formValid, setFormValid] = useState(
    !require_address && !require_discord_id && !require_merch_size
  );

  const { mobile } = useWindowSize();

  useEffect(() => {
    const checkValidity = () => {
      const isValid = false;
      if (!require_address && !require_discord_id && !require_merch_size)
        return true;
      if (require_discord_id && !discordId) return false;
      if (require_merch_size && !size) return false;
      if (
        require_address &&
        (!address.name ||
          !address.street ||
          !address.city ||
          !address.state ||
          !address.country)
      )
        return false;
      return true;
    };
    setFormValid(checkValidity());
  }, [discordId, size, address, size]);

  useEffect(() => {
    if (user) {
      setDiscordId(user.discordId);
      setAddress({
        name: user?.name,
        street: user?.address?.street,
        street2: user?.address?.street2,
        city: user?.address?.city,
        state: user?.address?.state,
        zip: user?.address?.zip,
        country: user?.address?.country,
      });
      setSize(user.size);
      setEmail(user.email);
    }
  }, [user]);

  if (!winningPrize) return <></>;
  const prize = prizes.find((p) => p?.id === prismicId);
  const {
    data: {
      image: { url },
      confirmation_message,
    },
  } = prize || { data: { image: {} } };

  const submitConfirmationModalForm = async () => {
    if (require_address || require_discord_id || require_merch_size) {
      setLoading(true);
      const result = await api
        .post({
          endpoint: `/users/user/${user.id}`,
          data: {
            name: address.name,
            address,
            discordId,
            size,
            email,
          },
        })
        .then((response) => response.data);
      setUser(result);
      setLoading(false);
    }
    onClose();
  };

  return (
    <Modal
      title={
        <Title order={1} transform="uppercase">
          Spin Confirmation
        </Title>
      }
      className="max-w-screen"
      size={mobile ? "100%" : 500}
      opened={opened}
      onClose={onClose}
      withCloseButton={
        !require_address && !require_discord_id && !require_merch_size
      }
    >
      {winningPrize && prize && (
        <div>
          <Group direction="column" grow noWrap align="flex-start">
            {!require_address && (
              <div>
                <Image
                  height={200}
                  width={mobile ? "100%" : 460}
                  radius="sm"
                  src={url}
                  fit="cover"
                />
              </div>
            )}
            <Group className="w-full" direction="column" grow>
              <Group
                noWrap
                align="flex-start"
                className="flex-wrap sm:flex-nowrap"
              >
                {require_address && (
                  <Image
                    width={200}
                    height={150}
                    radius="sm"
                    src={url}
                    fit="cover"
                  />
                )}
                <Stack spacing="xs">
                  <Title order={3}>{name}</Title>
                  <Text size="sm">
                    {confirmation_message ? (
                      <RichText render={confirmation_message} />
                    ) : (
                      `Congratulations! You won ${name}. If your token is on chain, please wait a few minutes for your prize to be distributed. Thanks for playing!`
                    )}
                  </Text>
                  {winningPrize?.paidTransactionId && (
                    <Text>
                      Transaction Id:{" "}
                      <Anchor
                        target="_blank"
                        href={`https://solscan.io/tx/${winningPrize.paidTransactionId}`}
                      >
                        {shortAddress(winningPrize.paidTransactionId)}
                      </Anchor>
                    </Text>
                  )}
                  {winningPrize?.mint && (
                    <Text>
                      Mint: <Anchor>{shortAddress(winningPrize.mint)}</Anchor>
                    </Text>
                  )}
                </Stack>
              </Group>

              <Group grow direction={require_address ? "row" : "column"}>
                {require_discord_id && (
                  <InputWrapper id="discordId" required label="Discord id">
                    <Input
                      id="discordId"
                      name="discordId"
                      value={discordId}
                      onChange={(e) => setDiscordId(e.currentTarget.value)}
                    />
                  </InputWrapper>
                )}
                {require_merch_size && (
                  <InputWrapper id="size" required label="Merch size">
                    <Input
                      id="size"
                      name="size"
                      value={size}
                      onChange={(e) => setSize(e.currentTarget.value)}
                    />
                  </InputWrapper>
                )}
              </Group>
              {require_address && (
                <InputWrapper
                  id="email"
                  required
                  label="Email"
                  className="w-full"
                >
                  <Input
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.currentTarget.value)}
                  />
                </InputWrapper>
              )}
              {require_address && (
                <AddressForm address={address} setAddress={setAddress} />
              )}
              <Button
                disabled={!formValid}
                className="mt-auto"
                onClick={submitConfirmationModalForm}
              >
                {loading ? <Loader /> : <span>OK</span>}
              </Button>
            </Group>
          </Group>
        </div>
      )}
    </Modal>
  );
}
export default ConfirmationModal;
