/* eslint-disable react/destructuring-assignment */
import {
  Divider,
  Grid,
  Text,
  Tooltip,
  Button,
  Center,
  Anchor,
  useMantineTheme,
} from "@mantine/core";
import { useClipboard } from "@mantine/hooks";
import React from "react";
import { CircleCheck, CircleMinus } from "tabler-icons-react";
import _ from "lodash";
import { formatTime, shortAddress } from "../utils/utils";
import useWindowSize from "../utils/hooks/useWindowSize";

export default function LineItems({ columns, items }) {
  const isDevNet = process.env.REACT_APP_SOLANA_NETWORK === "devnet";
  const theme = useMantineTheme();
  const clipboard = useClipboard({ timeout: 500 });
  const { mobile } = useWindowSize();

  if (!items || items.length === 0) return <div>No results found</div>;

  const cols = columns.map((col) => col.cols).reduce((acc, col) => acc + col);

  const getCell = (item, firstValue, spin, secondValue) => {
    const value = firstValue || secondValue;
    let result = value;
    if (item.short) result = shortAddress(value);
    if (item.checkbox) {
      return value ? (
        <CircleCheck color={theme.colors.green[5]} />
      ) : (
        <CircleMinus color={theme.colors.red[5]} />
      );
    }
    if (item.date) return value ? formatTime(value) : "-";
    if (item.link) {
      return value ? (
        <a href={value} target="_blank" rel="noreferrer">
          {result}
        </a>
      ) : (
        "-"
      );
    }
    if (item.withCopyTooltip) {
      return value ? (
        <Tooltip
          label={
            <Center>{clipboard.copied ? "Copied!" : "Click to Copy"}</Center>
          }
          width="95px"
          withArrow
        >
          <Button
            variant="outline"
            compact
            type="button"
            onClick={() => clipboard.copy(value)}
          >
            <Text size="sm">{result}</Text>
          </Button>
        </Tooltip>
      ) : (
        "-"
      );
    }
    if (item.modal) {
      return (
        <Tooltip label="Click to Edit" withArrow>
          <Button
            variant="outline"
            compact
            type="button"
            onClick={() => item.onClick(spin)}
          >
            <Text size="sm">{result} </Text>
          </Button>
        </Tooltip>
      );
    }
    if (item.wallet) {
      return value ? (
        <Anchor target="_blank" href={`https://solscan.io/account/${value}`}>
          <Text size="sm">{result}</Text>
        </Anchor>
      ) : (
        "-"
      );
    }

    if (
      (item.id === "burnTransactionId" || item.id === "paidTransactionId") &&
      value
    ) {
      return (
        <a
          href={`https://solscan.io/tx/${value}${
            isDevNet ? "?cluster=devnet" : ""
          }`}
          target="_blank"
          rel="noreferrer"
        >
          {shortAddress(value)}
        </a>
      );
    }
    return result || "-";
  };

  return (
    <div>
      {items.map((item, i) => {
        const odd = i % 2;
        return (
          <div key={item._id || i} className={odd ? "bg-gray-800" : ""}>
            {!!odd && <Divider className="w-full" />}
            <Grid className="my-3 px-2" columns={mobile ? 2 : cols} gutter="xl">
              {columns.map((col) => {
                return (
                  <Grid.Col key={col.id || i} span={mobile ? 1 : col.cols}>
                    <Text className="text-white font-semibold" size="xs">
                      {col.label}
                    </Text>
                    <Text className="text-white" size="sm">
                      {col.id === "i"
                        ? i + 1
                        : getCell(
                            col,
                            _.get(item, col.id),
                            item,
                            _.get(item, col.secondId)
                          )}
                    </Text>
                  </Grid.Col>
                );
              })}
            </Grid>
            {!!odd && <Divider className="w-full" />}
          </div>
        );
      })}
    </div>
  );
}
