import React from "react";
import { ActionIcon, Anchor, Group, Image, Text } from "@mantine/core";
import { BrandDiscord, BrandTwitter } from "tabler-icons-react";

function Header() {
  return (
    <Group
      position="apart"
      className="bg-opacity-10 bg-white md:px-16 px-5 py-5"
    >
      <Image width={250} src="/solyetis/logo.png" />
      <Group spacing="lg">
        <Anchor href="https://solyetis.io/">
          <Text className="font-bold text-xl">PROJECT</Text>
        </Anchor>
        <Anchor href="https://raydium.io/swap/?inputCurrency=sol&outputCurrency=FDKBUXKxCdNQnDrqP7DLe8Kri3hzFRxcXyoskoPa74rk&outputAmount=0&fixed=in">
          <Text className="font-bold text-xl" target="_blank">
            BUY $YETI
          </Text>
        </Anchor>
        <Anchor href="https://solyetis.io/#roadmap">
          <Text className="font-bold text-xl">ROADMAP</Text>
        </Anchor>
        <Anchor href="https://www.magiceden.io/marketplace/solyetis">
          <Text className="font-bold text-xl">BUY YETIS</Text>
        </Anchor>
        <Anchor href="https://solyetis.io/#showcase">
          <Text className="font-bold text-xl">SHOWCASE</Text>
        </Anchor>
        <Anchor href="https://staking.solyetis.io/">
          <Text className="font-bold text-xl">STAKING</Text>
        </Anchor>
        <Group spacing="xs">
          <ActionIcon
            component="a"
            href="https://twitter.com/SolYetis"
            target="_blank"
            size={35}
          >
            <BrandTwitter />
          </ActionIcon>
          <ActionIcon
            component="a"
            href="https://discord.com/invite/ZVr7sYs7jn"
            target="_blank"
            size={35}
          >
            <BrandDiscord />
          </ActionIcon>
        </Group>
      </Group>
    </Group>
  );
}

export default Header;
