/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Wheel as CustomWheel } from "react-custom-roulette";
import {
  Container,
  Group,
  Image,
  Skeleton,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { StoreContext } from "../../providers/StoreProvider";
import ConfirmationModal from "../../pages/home/components/ConfirmationModal";

function Wheel({
  spinning,
  winningPrize,
  handleSpinEnd,
  winningPrizeNumber,
  data,
}) {
  const { prizes, sendPrize } = React.useContext(StoreContext);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [prizeSent, setPrizeSent] = useState(undefined);
  const theme = useMantineTheme();
  const wheelSliceColors = [
    "#A5D8FF",
    "#74C0FC",
    "#4DABF7",
    "#339AF0",
    "#228BE6",
    "#1C7ED6",
    "#1971C2",
    "#1864AB",
  ];

  const onPrizeWon = async () => {
    if (!winningPrize)
      showNotification({
        id: "error",
        title: "Error!",
        message: "No winning spin found",
        color: "red",
      });
    else {
      const result = await sendPrize(winningPrize.id);
      setPrizeSent(result);
      handleSpinEnd();
      if (result) setConfirmationModalOpen(true);
    }
  };

  return (
    <>
      <Container size={1200}>
        <Group direction="column" align="center">
          <Group position="center">
            {!prizes?.length && <Skeleton height={500} circle mb="xl" />}
            {!!prizes?.length && (
              <div className="relative">
                <img
                  alt="logo"
                  width={110}
                  height={110}
                  src="/solyetis/wheel-logo.png"
                  className="border-2 border-white bg-white border-solid absolute z-20 top-50 left-50 rounded-full"
                />
                <div className="bg-wheel bg-contain md:w-[530px] md:h-[530px] flex justify-center items-center">
                  <div className="wheel">
                    <CustomWheel
                      mustStartSpinning={spinning}
                      prizeNumber={winningPrizeNumber}
                      data={data}
                      backgroundColors={wheelSliceColors}
                      textColors={["#fff"]}
                      textDistance={60}
                      fontSize={18}
                      radiusLineColor="#fff"
                      outerBorderColor="#fff"
                      innerRadius={20}
                      radiusLineWidth={3}
                      onStopSpinning={onPrizeWon}
                    />
                  </div>
                </div>
              </div>
            )}
          </Group>
        </Group>
      </Container>
      <ConfirmationModal
        opened={confirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
        winningPrize={prizeSent}
      />
    </>
  );
}

export default Wheel;
