import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Input,
  InputWrapper,
  Loader,
  Space,
  Stack,
  Text,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import api from "../../../api/api";
import { StoreContext } from "../../../providers/StoreProvider";
import AddressForm from "../../AddressForm";

function Profile() {
  const { user, setUser } = React.useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(user?.email);
  const [discordId, setDiscordId] = useState(user?.discordId);
  const [size, setSize] = useState(user?.size);
  const [address, setAddress] = useState({
    name: user?.name,
    street: user?.address?.street,
    street2: user?.address?.street2,
    city: user?.address?.city,
    state: user?.address?.state,
    zip: user?.address?.zip,
    country: user?.address?.country,
  });

  useEffect(() => {
    if (user) {
      setDiscordId(user.discordId);
      setAddress({
        name: user?.name,
        street: user?.address?.street,
        street2: user?.address?.street2,
        city: user?.address?.city,
        state: user?.address?.state,
        zip: user?.address?.zip,
        country: user?.address?.country,
      });
      setSize(user.size);
      setEmail(user.email);
    }
  }, [user]);

  const saveInfo = async () => {
    setLoading(true);
    try {
      const result = await api
        .post({
          endpoint: `/users/user/${user.id}`,
          data: {
            name: address.name,
            address,
            discordId,
            size,
            email,
          },
        })
        .then((response) => response.data);
      setUser(result);
      showNotification({
        id: "profile",
        autoClose: 3000,
        title: "Success: Profile updated",
        color: "green",
      });
    } catch (error) {
      showNotification({
        id: "profile",
        autoClose: 3000,
        title: "Error: can't update profile",
        message: error.message,
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Space h="lg" />
      <Text>
        Your contact information is required to send out prizes when you win an
        off chain prize. Please check details of spins you have won to see if
        this information is required.
      </Text>
      <Text>
        We reccomend you enter your <b>Discord Id</b> so we have a way of
        contacting you.
      </Text>
      <Space h="lg" />
      <Stack>
        <InputWrapper id="discordId" required label="Discord Id">
          <Input
            id="discordId"
            name="discordId"
            value={discordId}
            onChange={(e) => setDiscordId(e.currentTarget.value)}
          />
        </InputWrapper>
        <InputWrapper id="email" required label="Email">
          <Input
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
        </InputWrapper>
        <InputWrapper
          id="size"
          required
          label="Merch Size"
          description="Example: Shirt - Small, shoes - 7 US Mens"
        >
          <Input
            id="size"
            name="size"
            value={size}
            onChange={(e) => setSize(e.currentTarget.value)}
          />
        </InputWrapper>
        <AddressForm address={address} setAddress={setAddress} />
        <Button onClick={saveInfo}>
          {loading ? <Loader /> : <span>Save</span>}
        </Button>
      </Stack>
    </Container>
  );
}

export default Profile;
