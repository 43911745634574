/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import {
  Anchor,
  Space,
  Text,
  Modal,
  Title,
  Stack,
  Button,
  Accordion,
  TextInput,
  Group,
  Switch,
  Checkbox,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { ExternalLink } from "tabler-icons-react";
import api from "../../../../api/api";
import { StoreContext } from "../../../../providers/StoreProvider";
import YesAndNoButtons from "./YesAndNoButtons";

function UpdateSpinModal({
  getSpins,
  activeSpinDetails,
  setActiveSpinDetails,
}) {
  const { user: adminUserDetails, prizeTypeMap } = useContext(StoreContext);
  const [removePrize, setRemovePrize] = useState(false);
  const [spinPurchaseSuccessful, setSpinPurchaseSuccessful] = useState(
    activeSpinDetails?.burnComplete
  );
  const [prizePaymentSuccessful, setPrizePaymentSuccessful] = useState(
    activeSpinDetails?.rewardsComplete
  );
  const [saving, setSaving] = useState(false);
  const [shippingInfo, setShippingInfo] = useState(
    activeSpinDetails?.shipping || {
      url: "",
      trackingId: "",
      carrier: "",
    }
  );

  const updateSpin = async () => {
    setSaving(true);
    try {
      await api.post({
        endpoint: "/auth/updateSpin",
        data: {
          adminUserId: adminUserDetails.id,
          id: activeSpinDetails._id,
          burnComplete: spinPurchaseSuccessful,
          rewardsComplete: prizePaymentSuccessful,
          shipping: shippingInfo,
          removePrize,
        },
      });
      showNotification({
        id: "update-success",
        title: "Successfully Updated Spin!",
        message: `Spin ID: ${activeSpinDetails._id}`,
        color: "green",
        disallowClose: false,
      });
      setActiveSpinDetails(undefined);
      getSpins();
    } catch (e) {
      console.log(e);
      showNotification({
        id: "update-error",
        title: "Updating Spin Failed!",
        message: "Please try again later",
        color: "red",
        disallowClose: false,
      });
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    setSpinPurchaseSuccessful(activeSpinDetails?.burnComplete);
    setPrizePaymentSuccessful(activeSpinDetails?.rewardsComplete);
    setShippingInfo(activeSpinDetails?.shipping);
  }, [activeSpinDetails]);

  return (
    <Modal
      title={<Title order={3}>Edit Spin Details</Title>}
      opened={!!activeSpinDetails}
      onClose={() => setActiveSpinDetails(undefined)}
      size={500}
    >
      {activeSpinDetails && (
        <>
          <Text size="md">
            Manually override Spin Transaction Details by updating the
            &quot;Purchase Successful&quot; and/or &quot;Payment
            Successful&quot; fields below
          </Text>

          <Space h="md" />
          <Stack spacing="sm">
            <div>
              <Text size="sm" color="gray">
                Spin Id
              </Text>
              <Text size="sm" className="break-all">
                {activeSpinDetails?._id}
              </Text>
            </div>
            <div>
              <Text size="sm" color="gray">
                Wallet
              </Text>
              <Text size="sm" className="break-all">
                {activeSpinDetails?.user?.wallet}
              </Text>
            </div>
            <div>
              <Text size="sm" color="gray">
                Discord Id
              </Text>
              <Text size="sm" className="break-all">
                {activeSpinDetails?.user?.discordId || "-"}
              </Text>
            </div>
            <div>
              <Text size="sm" color="gray">
                Winning Prize
              </Text>
              <Text size="sm" className="break-all">
                {activeSpinDetails?.prize?.name || "-"}
              </Text>
            </div>
            <Accordion iconSize={14} multiple>
              <Accordion.Item
                label={<Text size="lg">Purchase Successful?</Text>}
              >
                <YesAndNoButtons
                  value={spinPurchaseSuccessful}
                  setValue={setSpinPurchaseSuccessful}
                />

                <Text size="xs" color="red" className="ml-1 mt-2 flex gap-1">
                  Please check the burn transaction on{" "}
                  <Anchor
                    target="_blank"
                    href={`https://solscan.io/tx/${activeSpinDetails.burnTransactionId}`}
                    size="xs"
                    className="flex items-center w-fit"
                  >
                    <ExternalLink size={14} /> Solscan{" "}
                  </Anchor>
                  before updating
                </Text>
              </Accordion.Item>
              {/* On Chain Prize */}
              {(activeSpinDetails.prize.type === prizeTypeMap.sol ||
                activeSpinDetails.prize.type === prizeTypeMap.nft ||
                activeSpinDetails.prize.type === prizeTypeMap.token) && (
                <Accordion.Item
                  label={<Text size="lg">Payment Successful?</Text>}
                >
                  <YesAndNoButtons
                    value={prizePaymentSuccessful}
                    setValue={setPrizePaymentSuccessful}
                  />
                  <Text className="ml-1">
                    <Text weight={700}>Please check the burn transaction</Text>{" "}
                    <Anchor
                      target="_blank"
                      href={`https://solscan.io/tx/${activeSpinDetails.paidTransactionId}`}
                    >
                      on Solscan{" "}
                    </Anchor>
                    before updating
                  </Text>
                </Accordion.Item>
              )}
              <Accordion.Item label={<Text size="lg">Spin Credit</Text>}>
                <Switch
                  removePrize={removePrize}
                  onChange={(event) =>
                    setRemovePrize(event.currentTarget.removePrize)
                  }
                  label="Remove prize"
                  disabled={
                    activeSpinDetails.paidTransactionId &&
                    activeSpinDetails.rewardsComplete &&
                    activeSpinDetails.prize.type !== prizeTypeMap.offChain
                  }
                />
                {activeSpinDetails.paidTransactionId &&
                  activeSpinDetails.rewardsComplete && (
                    <Text color="red" size="xs">
                      Prize has already been sent{" "}
                      {activeSpinDetails.paidTransactionId}
                    </Text>
                  )}
                <Text className="ml-1 mt-2" size="xs">
                  If the prize is out of stock you can remove the prize rewarded
                  here. This will allocate the user a spin credit, enabling them
                  to spin the wheel for a different prize.
                </Text>
              </Accordion.Item>
              {/* Off Chain Prize */}
              {activeSpinDetails.prize.type === prizeTypeMap.offChain && (
                <Accordion.Item
                  label={<Text size="lg">Payment Successful?</Text>}
                >
                  <YesAndNoButtons
                    value={prizePaymentSuccessful}
                    setValue={setPrizePaymentSuccessful}
                  />

                  <Text className="ml-1 mt-2" size="xs">
                    Prize type is Off Chain. Please only update this field if
                    the prize has been shipped and/or delivered to the winning
                    user.
                  </Text>
                </Accordion.Item>
              )}
              {/* Shipping info for Off Chain Prize */}
              {activeSpinDetails.prize.type === prizeTypeMap.offChain && (
                <Accordion.Item
                  label={<Text size="lg">Shipping Information</Text>}
                >
                  <Text className="mb-1" size="xs">
                    If a shipping method was used to deliver the off-chain
                    prize, please enter a link to the tracking information
                    below. The winning user will have access to this information
                    from the user panel.
                  </Text>
                  <Group position="apart" grow>
                    <TextInput
                      value={shippingInfo?.carrier}
                      onChange={(e) =>
                        setShippingInfo({
                          ...shippingInfo,
                          carrier: e.target.value,
                        })
                      }
                      placeholder="Carrier"
                      label="Carrier"
                    />
                    <TextInput
                      value={shippingInfo?.trackingId}
                      onChange={(e) =>
                        setShippingInfo({
                          ...shippingInfo,
                          trackingId: e.target.value,
                        })
                      }
                      placeholder="Tracking Id"
                      label="Tracking Id"
                    />
                  </Group>
                  <TextInput
                    className="mt-1"
                    value={shippingInfo?.url}
                    onChange={(e) =>
                      setShippingInfo({ ...shippingInfo, url: e.target.value })
                    }
                    placeholder="enter a link"
                    label="Tracking Link"
                  />
                </Accordion.Item>
              )}
            </Accordion>
            <Button loading={saving} onClick={updateSpin}>
              Save
            </Button>
          </Stack>
        </>
      )}
    </Modal>
  );
}
export default UpdateSpinModal;
