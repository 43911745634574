import { Anchor, Container, Grid, Text } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { CircleCheck, ExternalLink, X } from "tabler-icons-react";
import api from "../../../../api/api";
import useWindowSize from "../../../../utils/hooks/useWindowSize";

export default function Prizes() {
  const [prizes, setPrizes] = useState([]);
  const { mobile } = useWindowSize();
  const cols = mobile ? 2 : 24;
  const getPrizes = async () => {
    await api
      .get({
        endpoint: "/auth/prizes",
      })
      .then((response) =>
        setPrizes(response?.data?.sort((a) => (a.active ? -1 : 1)) || [])
      );
  };

  useEffect(() => {
    getPrizes();
  }, []);
  return (
    <Container size={1500} px={20}>
      {prizes.map((prize) => {
        return (
          <Grid columns={cols} gutter="sm" key={prize.id}>
            <Grid.Col span={mobile ? 1 : 4}>
              <Text size="sm" color="gray">
                Name
              </Text>
              <Text size="ms">{prize.name}</Text>
            </Grid.Col>
            <Grid.Col span={mobile ? 1 : 4}>
              <Text size="sm" color="gray">
                Probability
              </Text>
              <Text size="ms">{prize.probability}</Text>
            </Grid.Col>
            <Grid.Col span={mobile ? 1 : 4}>
              <Text size="sm" color="gray">
                Active Prize
              </Text>
              {prize.active ? <CircleCheck color="green" /> : <X color="red" />}
            </Grid.Col>
            <Grid.Col span={mobile ? 1 : 4}>
              <Text size="sm" color="gray">
                Type
              </Text>
              <Text size="ms">{prize.type}</Text>
            </Grid.Col>
            <Grid.Col span={mobile ? 1 : 4}>
              <Text size="sm" color="gray">
                Amount
              </Text>
              <Text size="ms">{prize.amount}</Text>
            </Grid.Col>
            <Grid.Col span={mobile ? 1 : 4}>
              <Text size="sm" color="gray">
                Prismic Id
              </Text>
              <Anchor
                target="_blank"
                href={`https://spinner-staging.prismic.io/documents~b=working&c=published&l=en-us/${prize.prismicId}/`}
                className="flex gap-1"
              >
                <Text size="ms">{prize.prismicId}</Text> <ExternalLink />
              </Anchor>
            </Grid.Col>
          </Grid>
        );
      })}
    </Container>
  );
}
