export const filterOptions = [
  { value: "_id", label: "Spin Id" },
  { value: "burnTransactionId", label: "Burn TXN" },
  { value: "paidTransactionId", label: "Payment TXN" },
  { value: "user", label: "User Id" },
  { value: "wallet", label: "Wallet" },
  { value: "rewardsComplete", label: "Payment Successful?" },
  { value: "burnComplete", label: "Burn Successful?" },
];
export const getColumns = (onSpinIdClick) => {
  return [
    {
      label: "Spin Id",
      id: "id",
      cols: 4,
      short: true,
      modal: true,
      onClick: onSpinIdClick,
    },
    { label: "Prize", id: "prize.name", cols: 4, short: false },
    {
      label: "Prize Id",
      id: "prize.id",
      cols: 5,
      short: true,
      withCopyTooltip: true,
    },
    {
      label: "Burn txn",
      id: "burnTransactionId",
      cols: 4,
      short: true,
    },
    {
      label: "Burn",
      id: "burnComplete",
      cols: 2,
      short: false,
      checkbox: true,
    },
    {
      label: "Paid txn",
      id: "paidTransactionId",
      cols: 4,
      short: true,
    },
    {
      label: "Paid",
      id: "rewardsComplete",
      cols: 2,
      short: false,
      checkbox: true,
    },
    {
      label: "User Id",
      id: "user.id",
      cols: 5,
      short: true,
      withCopyTooltip: true,
    },
    {
      label: "User Wallet",
      id: "user.wallet",
      cols: 4,
      short: true,
      wallet: true,
    },
    {
      label: "Discord Id",
      id: "user.discordId",
      cols: 4,
    },
    {
      label: "Date",
      id: "updatedAt",
      cols: 4,
      date: true,
    },
  ];
};
