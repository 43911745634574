/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Divider,
  Space,
  Group,
  Select,
  Title,
  SegmentedControl,
  Pagination,
  Button,
  Skeleton,
  TextInput,
} from "@mantine/core";
import api from "../../../../api/api";
import { StoreContext } from "../../../../providers/StoreProvider";
import LineItems from "../../../../components/LineItems";
import UpdateSpinModal from "./UpdateSpinModal";
import YesAndNoButtons from "./YesAndNoButtons";
import { downloadCSVFile } from "../../utils/csv";
import { getColumns, filterOptions } from "./config";

function AllSpins() {
  const { prizeTypes } = React.useContext(StoreContext);
  // all spins
  const [spins, setSpins] = useState([]);
  // total number of spins by all users
  const [total, setTotal] = useState(0);
  // type (sol, token, nft, etc)
  const [value, setValue] = useState("all");
  // control pagination state
  const [activePage, setPage] = useState(1);
  // control dropdown filter state
  const [filter, setFilter] = useState();
  // control checkbox filter state
  const [filterValue, setFilterValue] = useState(false);
  // selected spin to update
  const [activeSpinDetails, setActiveSpinDetails] = useState();
  const [loading, setLoading] = useState(false);
  const ref = useRef();
  const values = prizeTypes.map((type) => {
    return { label: type || "All", value: type || "all" };
  });

  const onSpinIdClick = (spin) => setActiveSpinDetails(spin);
  const columns = getColumns(onSpinIdClick);

  const getSpins = useCallback(
    async (skip) => {
      if (filterValue && filter && filterValue.length < 24) {
        return;
      }
      if (!filterValue && filter) return;
      setLoading(true);

      const result = await api
        .post({
          endpoint: `/auth/spins/${value}/${skip || 0}`,
          data: {
            filter,
            filterValue,
          },
        })
        .then((response) => response?.data);
      setSpins(result?.spins);
      setTotal(result?.count || 0);
      setLoading(false);
    },
    [value, filter, filterValue]
  );

  useEffect(() => {
    if (filter === "burnComplete" || filter === "rewardsComplete") {
      setFilterValue(false);
    } else {
      setFilterValue("");
      ref.current.value = "";
    }
  }, [filter]);

  useEffect(() => {
    getSpins();
  }, [getSpins]);

  const paginate = (page) => {
    setPage(page);
    getSpins((page - 1) * 30);
  };

  return (
    <div>
      <Group position="apart" align="end">
        <Group align="center">
          <SegmentedControl
            className="mt-6"
            value={value}
            onChange={setValue}
            data={values}
            color="blue"
          />

          <Divider
            orientation="vertical"
            style={{ height: "35px" }}
            className="mt-6"
          />
          <Select
            data={filterOptions}
            value={filter}
            onChange={setFilter}
            clearable
            label="Filter"
          />
          {filter !== "burnComplete" && filter !== "rewardsComplete" ? (
            <TextInput
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
              disabled={!filter}
              placeholder="Must be exact"
              label="Filter Value"
              ref={ref}
              required
            />
          ) : (
            <div className="self-end">
              <YesAndNoButtons value={filterValue} setValue={setFilterValue} />
            </div>
          )}
        </Group>
        <Group>
          <Button
            onClick={() =>
              downloadCSVFile({
                value,
                filter,
                filterValue,
              })
            }
          >
            Download CSV
          </Button>
          <Title order={3}>{total} Results</Title>
        </Group>
      </Group>
      <Space h="lg" />
      {loading && Array(30).fill(<Skeleton height="97.88px" />)}
      {!loading && (
        <div>
          <LineItems columns={columns} items={spins} />
        </div>
      )}
      <Space h="md" />
      <Pagination
        position="right"
        age={activePage}
        onChange={paginate}
        total={Math.ceil(total / 30)}
      />
      <UpdateSpinModal
        getSpins={getSpins}
        setActiveSpinDetails={setActiveSpinDetails}
        activeSpinDetails={activeSpinDetails}
      />
    </div>
  );
}
export default AllSpins;
