import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Container,
  Group,
  Menu,
  SimpleGrid,
  Title,
} from "@mantine/core";
import { useWallet } from "@solana/wallet-adapter-react";
import { InfoCircle } from "tabler-icons-react";
import Wheel from "../../components/Wheel/Wheel";
import { StoreContext } from "../../providers/StoreProvider";
import PrizeCard from "./components/PrizeCard";
import InfoModal from "../../components/InfoModal/InfoModal";
import CreditModal from "../../components/CreditModal";
import UnderMaintenanceModal from "./components/UnderMaintenanceModal";

function Home() {
  const {
    spinWheel,
    user,
    prizes,
    credits,
    spinWithCredit,
    underMaintenance,
    getUserCollectionNfts,
    burnNftToSpin,
  } = React.useContext(StoreContext);
  const [spinning, setSpinning] = useState(false);
  const [infoModalOpened, setInfoModalOpenedOpened] = useState(false);
  const [winningPrize, setWinningPrize] = useState();
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const [prizeIndex, setPrizeIndex] = useState(false);
  const [hasNft, setHasNft] = useState(false);
  const customWheelData = prizes.map((prize) => {
    return { option: prize.data.name };
  });

  const wallet = useWallet();
  const noActiveUser = !wallet.connected || !user?.id;
  const nftsExist = useCallback(async () => {
    const result = await getUserCollectionNfts();
    setHasNft(!!result?.length);
  }, [getUserCollectionNfts]);

  useEffect(() => {
    nftsExist();
  }, [nftsExist]);

  const handleSpinEnd = async () => {
    setSpinning(false);
    setPrizeIndex(-1);
  };

  const onSpinWheelClick = async () => {
    setLoading(true);
    try {
      const spinResults = await spinWheel();
      setWinningPrize(spinResults);
      if (spinResults) {
        const index = customWheelData.findIndex(
          (prz) =>
            prz.option.toLowerCase() === spinResults?.prize?.name.toLowerCase()
        );
        setPrizeIndex(index);
        if (index !== -1) setSpinning(true);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const onUseCredit = async () => {
    setLoading(true);
    try {
      const spinResults = await spinWithCredit();
      setWinningPrize(spinResults);
      if (spinResults) {
        const index = customWheelData.findIndex(
          (prz) =>
            prz.option.toLowerCase() === spinResults?.prize?.name.toLowerCase()
        );
        setPrizeIndex(index);
        if (index !== -1) setSpinning(true);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const onBurnNft = async () => {
    setLoading(true);
    try {
      const spinResults = await burnNftToSpin();
      setWinningPrize(spinResults);
      if (spinResults) {
        const index = customWheelData.findIndex(
          (prz) =>
            prz.option.toLowerCase() === spinResults?.prize?.name.toLowerCase()
        );
        setPrizeIndex(index);
        if (index !== -1) setSpinning(true);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const disabledText = wallet.connected
    ? "User not found, please refresh"
    : "Connect wallet to spin";

  return (
    <Container size={1200}>
      <UnderMaintenanceModal />

      <img
        className="absolute top-0 w-full h-screen left-0 -z-10 mask object-bottom  object-cover overflow-hidden"
        src="/solyetis/bg.png"
        alt="background"
      />
      <Wheel
        spinning={spinning}
        winningPrize={winningPrize}
        handleSpinEnd={handleSpinEnd}
        data={customWheelData}
        winningPrizeNumber={prizeIndex}
      />

      <Group position="center" className="mt-8">
        <Menu
          control={
            <Button
              disabled={noActiveUser || underMaintenance || spinning || loading}
              loading={loading}
            >
              {noActiveUser ? disabledText : "Spin"}
            </Button>
          }
        >
          <Menu.Item onClick={onSpinWheelClick}>Pay & spin</Menu.Item>
          <Menu.Item disabled={!credits.length} onClick={onUseCredit}>
            Use credit ({credits.length})
          </Menu.Item>
          <Menu.Item disabled={!hasNft} onClick={onBurnNft}>
            Sacrifice Baby Yeti
          </Menu.Item>
        </Menu>

        <Button
          variant="outline"
          leftIcon={<InfoCircle />}
          onClick={() => setInfoModalOpenedOpened(true)}
        >
          About
        </Button>
        <Menu
          control={
            <Button disabled={noActiveUser || underMaintenance}>
              Spin Credits
            </Button>
          }
        >
          <Menu.Item onClick={() => setOpened(true)}>Buy credits</Menu.Item>
          <Menu.Item disabled={!hasNft} onClick={onBurnNft}>
            Sacrifice Baby Yeti
          </Menu.Item>
        </Menu>
      </Group>
      <InfoModal
        opened={infoModalOpened}
        setOpened={setInfoModalOpenedOpened}
      />
      <Title className="my-12 mt-20 font-bold text-center" order={2}>
        Prize details
      </Title>
      <SimpleGrid
        cols={3}
        className="mt-5"
        breakpoints={[
          { maxWidth: 980, cols: 3, spacing: "md" },
          { maxWidth: 755, cols: 2, spacing: "sm" },
          { maxWidth: 600, cols: 1, spacing: "sm" },
        ]}
      >
        {prizes.map((prize) => (
          <PrizeCard prize={prize.data} />
        ))}
      </SimpleGrid>
      <CreditModal opened={opened} setOpened={setOpened} />
      {/* <BuyTokenModal /> */}
    </Container>
  );
}
export default Home;
