/* eslint-disable consistent-return */
import { showNotification } from "@mantine/notifications";
import axios from "axios";
import getToken from "../utils/jwt-token";

// function formatErrorResponse(error) {
//   const customError = error;
//   customError.status = error.response ? error.response.status : null;

//   return customError;
// }

export default async function api(options) {
  const { endpoint, user, id, responseType, ...otherOptions } = options;
  const token = getToken(endpoint, user);

  const url = process.env.REACT_APP_API_URL + endpoint;
  try {
    const result = await axios({
      headers: {
        token,
        "Content-Type": "application/json",
        // eslint-disable-next-line quote-props
        Accept: "application/json",
      },
      responseType,
      url,
      ...otherOptions,
    });

    return result;
  } catch (error) {
    showNotification({
      id: "generic-error",
      title: "Error!",
      message: error.response?.data?.error || error.message,
      color: "red",
      disallowClose: false,
    });
    return undefined;
  }
}

api.get = (options) => api({ ...options, method: "GET" });

api.post = (options) => api({ ...options, method: "POST" });

api.put = (options) => api({ ...options, method: "PUT" });

api.patch = (options) => api({ ...options, method: "PATCH" });

api.delete = (options) => api({ ...options, method: "DELETE" });
