import * as prismic from "@prismicio/client";

// Fill in your repository name
export const repositoryName = "https://shrouded-playground.prismic.io/api/v2";

export const client = prismic.createClient(repositoryName, {
  // If your repository is private, add an access token
  accessToken:
    "MC5Zc3ZTUEJBQUFDTUFaN1BQ.77-9Pe-_vS1UXDPvv73vv71GQO-_vS_vv73vv71BUEQG77-977-977-977-977-9Ih3vv718KUl177-9",
});
