import { Drawer, Group, Space, Tabs, Title } from "@mantine/core";
import React from "react";
import { Table, Trophy, UserCircle, Help } from "tabler-icons-react";
import Leaderboard from "./components/Leaderboard";
import Profile from "./components/Profile";
import SpinHistory from "./components/Spins";
import Info from "./components/Info";

export default function UserDrawer({ opened, onClose }) {
  const title = (
    <Group>
      <Title order={2}>User profile</Title>
    </Group>
  );
  return (
    <Drawer
      title={title}
      size={900}
      padding="xl"
      opened={opened}
      onClose={onClose}
      className="overflow-auto"
      withCloseButton
    >
      <Space h="sm" />
      <Tabs>
        <Tabs.Tab label="Spin history" icon={<Trophy />}>
          <SpinHistory />
        </Tabs.Tab>
        <Tabs.Tab label="Profile" icon={<UserCircle />}>
          <Profile />
        </Tabs.Tab>
        <Tabs.Tab label="Leaderboard" icon={<Table />}>
          <Leaderboard />
        </Tabs.Tab>
        {/* <Tabs.Tab label="Orders" icon={<Mailbox />}>
          <Orders />
        </Tabs.Tab> */}
        <Tabs.Tab label="Info" icon={<Help />}>
          <Info />
        </Tabs.Tab>
      </Tabs>
    </Drawer>
  );
}
