import {
  Divider,
  Button,
  Grid,
  Space,
  Text,
  Center,
  Tooltip,
  ActionIcon,
  Group,
} from "@mantine/core";
import { useClipboard } from "@mantine/hooks";
import React, { useState } from "react";
import { Plus } from "tabler-icons-react";
import { shortAddress } from "../../../../utils/utils";
import useWindowSize from "../../../../utils/hooks/useWindowSize";
import UserCreditModal from "./UserCreditModal";

const getAddressGrid = (cols, mobile, address) => {
  return (
    <Grid columns={cols} gutter="sm">
      <Grid.Col span={mobile ? 1 : 3}>
        <Text size="sm" color="gray">
          Street
        </Text>
        <Text size="ms">{address?.street || "-"}</Text>
      </Grid.Col>
      <Grid.Col span={mobile ? 1 : 3}>
        <Text size="sm" color="gray">
          Apt
        </Text>
        <Text size="ms">{address?.street2 || "-"}</Text>
      </Grid.Col>
      <Grid.Col span={mobile ? 1 : 3}>
        <Text size="sm" color="gray">
          City
        </Text>
        <Text size="ms">{address?.city || "-"}</Text>
      </Grid.Col>
      <Grid.Col span={mobile ? 1 : 3}>
        <Text size="sm" color="gray">
          State
        </Text>
        <Text size="ms">{address?.state || "-"}</Text>
      </Grid.Col>
      <Grid.Col span={mobile ? 1 : 3}>
        <Text size="sm" color="gray">
          Zip/ Postal Code
        </Text>
        <Text size="ms">{address?.zip || "-"}</Text>
      </Grid.Col>
      <Grid.Col span={mobile ? 1 : 3}>
        <Text size="sm" color="gray">
          Country
        </Text>
        <Text size="ms">{address?.country || "-"}</Text>
      </Grid.Col>
    </Grid>
  );
};

export default function UsersTable({ users }) {
  const [selectedUser, setSelectedUser] = useState();
  const clipboard = useClipboard({ timeout: 500 });
  const { mobile } = useWindowSize();
  const cols = mobile ? 2 : 20;

  if (users?.length === 0) return <Text>No results found</Text>;

  return (
    <>
      {users?.map((user, i) => {
        const toggle = i % 2;
        return (
          <div
            key={user._id}
            className={
              toggle
                ? "px-3 bg-gray-800 border-solid border-2 border-gray-800 rounded mb-0.5"
                : "px-3 bg-gray-900 border-solid border-2 border-gray-900 rounded mb-0.5"
            }
          >
            <Space h="xs" />
            <Grid columns={cols} gutter="sm">
              <Grid.Col span={mobile ? 1 : 3}>
                <Text size="sm" color="gray">
                  User Id
                </Text>
                <Tooltip
                  label={
                    <Center>
                      {clipboard.copied ? "Copied!" : "Click to Copy"}
                    </Center>
                  }
                  width="95px"
                  withArrow
                >
                  <Button
                    variant="outline"
                    compact
                    type="button"
                    onClick={() => clipboard.copy(user._id)}
                  >
                    <Text size="ms">{shortAddress(user._id)}</Text>
                  </Button>
                </Tooltip>
              </Grid.Col>
              <Grid.Col span={mobile ? 1 : 3}>
                <Text size="sm" color="gray">
                  Wallet
                </Text>
                <Tooltip
                  label={
                    <Center>
                      {clipboard.copied ? "Copied!" : "Click to Copy"}
                    </Center>
                  }
                  width="95px"
                  withArrow
                >
                  <Button
                    variant="outline"
                    compact
                    type="button"
                    onClick={() => clipboard.copy(user.wallet)}
                  >
                    <Text size="ms">{shortAddress(user.wallet)}</Text>
                  </Button>
                </Tooltip>
              </Grid.Col>
              <Grid.Col span={mobile ? 1 : 3}>
                <Text size="sm" color="gray">
                  Discord Id
                </Text>
                <Text size="ms">{user.discordId || "-"}</Text>
              </Grid.Col>

              <Grid.Col span={mobile ? 1 : 3}>
                <Text size="sm" color="gray">
                  Merch Size
                </Text>
                <Text size="sm" className="break-all">
                  {user.size || "-"}
                </Text>
              </Grid.Col>
              <Grid.Col span={mobile ? 1 : 3}>
                <Text size="sm" color="gray">
                  Name
                </Text>
                <Text>{user.name || "-"}</Text>
              </Grid.Col>

              <Grid.Col span={mobile ? 1 : 3}>
                <Text size="sm" color="gray">
                  Email
                </Text>
                <Text>{user.email || "-"}</Text>
              </Grid.Col>

              <Grid.Col span={mobile ? 1 : 2}>
                <Text size="sm" color="gray">
                  Spin Credits
                </Text>
                <Group align="center">
                  <ActionIcon
                    variant="outline"
                    size={20}
                    onClick={() => setSelectedUser(user)}
                  >
                    <Plus size={16} />
                  </ActionIcon>
                  <Text>{user.credits.length || "0"}</Text>
                </Group>
              </Grid.Col>
            </Grid>
            <Space h="xs" />
            <Divider color="blue" />
            <Space h="xs" />
            {getAddressGrid(cols, mobile, user.address)}
            <Space h="xs" />
          </div>
        );
      })}
      <UserCreditModal
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
      />
    </>
  );
}
