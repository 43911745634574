import {
  Container,
  Group,
  Pagination,
  SegmentedControl,
  Space,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { StoreContext } from "../../../providers/StoreProvider";
import LineItems from "../../LineItems";

const columns = [
  { label: "Spin Id", id: "id", cols: 3, short: true, withCopyTooltip: true },
  { label: "Prize", id: "prize.name", cols: 2, short: false },
  {
    label: "Burn txn",
    id: "burnTransactionId",
    cols: 3,
    short: true,
  },
  {
    label: "Burn",
    id: "burnComplete",
    cols: 1,
    short: false,
    checkbox: true,
  },
  {
    label: "Paid txn",
    id: "paidTransactionId",
    cols: 3,
    short: true,
  },
  {
    label: "Paid",
    id: "rewardsComplete",
    cols: 1,
    short: false,
    checkbox: true,
  },
  {
    label: "Date",
    id: "spinTime",
    secondId: "updatedAt",
    cols: 3,
    date: true,
  },
];
export default function SpinHistory() {
  const { userSpins, getUserSpins, prizeTypes } =
    React.useContext(StoreContext);
  const [activePage, setPage] = useState(1);
  const [value, setValue] = useState("all");
  const filterOptions = prizeTypes.map((type) => {
    return { label: type || "All", value: type || "all" };
  });

  const paginate = (page) => {
    setPage(page);
    getUserSpins((page - 1) * 8, value);
  };

  useEffect(() => {
    getUserSpins(0, value);
  }, [value]);

  return (
    <Container>
      <Group className="mt-2" position="right">
        <SegmentedControl
          value={value}
          onChange={setValue}
          data={filterOptions}
          color="blue"
        />
      </Group>
      <div>
        <LineItems columns={columns} items={userSpins.winners} />
      </div>
      <Space h="lg" />
      <Pagination
        position="right"
        age={activePage}
        onChange={paginate}
        total={Math.ceil(userSpins.count / 8)}
      />
    </Container>
  );
}
