import { Card, Container, Space, Tabs, Title } from "@mantine/core";
import { useWallet } from "@solana/wallet-adapter-react";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import Admins from "./components/Admins/Admins";
import AllSpins from "./components/AllSpins/AllSpins";
import Stats from "./components/Stats/Stats";
import Users from "./components/Users/Users";
import Orders from "./components/Orders/Orders";
import useQuery from "../../utils/hooks/useQuery";
import PrismicAuth from "./components/PrismicAuth";
import Prizes from "./components/Prizes/Prizes";
import { StoreContext } from "../../providers/StoreProvider";

function Admin() {
  const { user } = React.useContext(StoreContext);
  const wallet = useWallet();
  const modal = useWalletModal();
  const query = useQuery();
  const location = query.get("location");
  const initialTab = parseInt(location || 0, 10);
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = (loc) => {
    setSearchParams({ location: loc });
  };

  useEffect(() => {
    if (!user && !wallet.connected && !wallet.connecting && !wallet.publicKey)
      modal.setVisible(true);
  }, []);

  if (!user?.isAdmin)
    return (
      <Container>
        <Title>404 not found</Title>
      </Container>
    );

  return (
    <Container className="padding" size={1500} p="lg">
      <PrismicAuth />
      <Title>Admin Dashboard</Title>
      <Stats />
      <Card className="w-full min-h-screen pl-0">
        <Card.Section p="lg">
          <Container size={1500} className="w-full px-3">
            <Tabs
              onTabChange={navigate}
              initialTab={initialTab}
              variant="pills"
              grow
            >
              <Tabs.Tab label="Admins">
                <Admins />
              </Tabs.Tab>
              <Tabs.Tab label="Prizes">
                <Prizes />
              </Tabs.Tab>
              <Tabs.Tab label="All Spins">
                <AllSpins />
              </Tabs.Tab>
              <Tabs.Tab label="Users">
                <Users />
              </Tabs.Tab>
              <Tabs.Tab label="Orders">
                <Orders />
              </Tabs.Tab>
            </Tabs>
          </Container>
        </Card.Section>
      </Card>
      <Space h="lg" />
    </Container>
  );
}

export default Admin;
