import React from "react";
import { Button, Group, useMantineTheme } from "@mantine/core";
import { CircleCheck, CircleMinus } from "tabler-icons-react";

function YesAndNoButtons({ value, setValue, className }) {
  const theme = useMantineTheme();

  return (
    <Group className={className}>
      <Button
        leftIcon={
          <CircleCheck color={value ? "white" : theme.colors.green[5]} />
        }
        onClick={() => setValue(true)}
        variant={value ? "filled" : "outline"}
        color="green"
      >
        Yes
      </Button>
      <Button
        leftIcon={<CircleMinus color={value ? theme.colors.red[5] : "white"} />}
        onClick={() => setValue(false)}
        variant={value === false ? "filled" : "outline"}
        color="red"
      >
        No
      </Button>
    </Group>
  );
}
export default YesAndNoButtons;
