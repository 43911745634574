import {
  Space,
  Group,
  Select,
  TextInput,
  Title,
  Pagination,
  Skeleton,
} from "@mantine/core";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { StoreContext } from "../../../../providers/StoreProvider";
import UsersTable from "./UsersTable";

const filterOptions = [
  { value: "_id", label: "User Id" },
  { value: "wallet", label: "Wallet" },
];

function Users() {
  const { users, getUsers } = React.useContext(StoreContext);
  const [activePage, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState();
  const [filterValue, setFilterValue] = useState();
  const ref = useRef();

  const searchUsers = useCallback(
    async (skip) => {
      if (
        (filter && !filterValue) ||
        (filterValue && filter && filterValue.length < 24)
      )
        return;
      setLoading(true);
      await getUsers(skip, filter, filterValue);
      setLoading(false);
    },
    [filter, filterValue]
  );

  useEffect(() => {
    setFilterValue(undefined);
    ref.current.value = "";
  }, [filter]);

  useEffect(() => {
    searchUsers();
  }, [searchUsers]);

  const paginate = (page) => {
    setPage(page);
    getUsers((page - 1) * 10);
  };

  return (
    <div>
      <Group position="apart" align="end">
        <Group align="center">
          <Select
            data={filterOptions}
            value={filter}
            onChange={setFilter}
            clearable
            label="Filter"
          />
          <TextInput
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
            disabled={!filter}
            placeholder="Must be exact"
            label="Filter Value"
            ref={ref}
          />
        </Group>
        <Title order={3}>{users?.count || 0} Results</Title>
      </Group>
      <Space h="lg" />
      {loading &&
        Array(10).fill(
          <Skeleton
            height="140px"
            className="px-3 bg-gray-800 border-solid border-x-2 border-gray-900 border-y"
          />
        )}
      {!loading && <UsersTable users={users?.users} />}
      <Space h="md" />
      <Pagination
        position="right"
        age={activePage}
        onChange={paginate}
        total={Math.ceil((users?.count || 0) / 30)}
      />
    </div>
  );
}
export default Users;
