import { Button, Group } from "@mantine/core";
import { useWallet } from "@solana/wallet-adapter-react";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import React, { useState } from "react";
import { Copy } from "tabler-icons-react";
import { copyTextToClipboard, shortAddress } from "../utils/utils";

function Wallet({ closeConnectWalletWarning }) {
  const walletModal = useWalletModal();
  const wallet = useWallet();
  const [copying, setCopying] = useState(false);

  const copyAddress = async () => {
    const callback = async () => {
      await copyTextToClipboard(wallet.publicKey.toString());
      setCopying(false);
    };

    setCopying(true);
    setTimeout(callback, 500);
  };
  return (
    <Group className="justify-end sm:justify-start">
      {!wallet.connected ? (
        <Button
          onClick={() => {
            closeConnectWalletWarning();
            walletModal.setVisible(true);
          }}
        >
          Connect
        </Button>
      ) : (
        <Button onClick={() => wallet.disconnect()}>Disconnect</Button>
      )}
      {wallet.connected && (
        <Button onClick={copyAddress} leftIcon={<Copy />} variant="outline">
          {copying ? "Copied" : shortAddress(wallet.publicKey.toString())}
        </Button>
      )}
      {wallet.connected && (
        <Button onClick={() => walletModal.setVisible(true)} variant="light">
          Change wallet
        </Button>
      )}
    </Group>
  );
}

export default Wallet;
