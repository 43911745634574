import { Anchor, Group, Text } from "@mantine/core";
import React from "react";

function Footer() {
  return (
    <>
      <div className="mt-auto">
        <div className="mt-20 flex flex-col items-center bg-black justify-end">
          <Group
            align="center"
            position="apart"
            className="w-full"
            direction="column"
            spacing="xs"
          >
            <Group spacing="sm" position="center" align="center">
              <Anchor
                href="https://discord.gg/2eDtjyR8"
                target="_blank"
                className="hover:no-underline ml-auto"
              >
                <Group
                  id="socials"
                  className={`"h-auto p-2 bg-black overflow-hidden mt-auto`}
                  position="center"
                  spacing={5}
                >
                  <Text className="text-white text-xs">
                    © 2022 This site is powered by Infinity 3
                  </Text>
                </Group>
              </Anchor>
            </Group>
          </Group>
        </div>
      </div>
    </>
  );
}

export default Footer;
