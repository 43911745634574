import React from "react";
import { InputWrapper, Input, Stack, Group } from "@mantine/core";

function AddressForm({ address, setAddress }) {
  const handleOnChange = (e) => {
    setAddress({
      ...address,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Stack>
      <InputWrapper id="name" required label="Name or Company">
        <Input
          id="name"
          name="name"
          value={address.name}
          onChange={handleOnChange}
        />
      </InputWrapper>
      <InputWrapper id="street" required label="Street Address">
        <Input
          id="street"
          name="street"
          value={address.street}
          onChange={handleOnChange}
        />
      </InputWrapper>
      <InputWrapper id="street2" label="Apt, Suite, etc.">
        <Input
          id="street2"
          name="street2"
          value={address.street2}
          onChange={handleOnChange}
        />
      </InputWrapper>
      <Group grow>
        <InputWrapper id="city" required label="City">
          <Input
            id="city"
            name="city"
            value={address.city}
            onChange={handleOnChange}
          />
        </InputWrapper>
        <InputWrapper id="zip" required label="Zip/ Postal Code">
          <Input
            id="zip"
            name="zip"
            value={address.zip}
            onChange={handleOnChange}
          />
        </InputWrapper>
      </Group>
      <Group className="w-full" grow>
        <InputWrapper id="state" required label="State">
          <Input
            id="state"
            name="state"
            value={address.state}
            onChange={handleOnChange}
          />
        </InputWrapper>
        <InputWrapper id="country" required label="Country">
          <Input
            id="country"
            name="country"
            value={address.country}
            onChange={handleOnChange}
          />
        </InputWrapper>
      </Group>
    </Stack>
  );
}

export default AddressForm;
