import { Container } from "@mantine/core";
import React from "react";
import { StoreContext } from "../../../providers/StoreProvider";
import LineItems from "../../LineItems";

const columns = [
  { label: "#", id: "i", cols: 1, short: false },
  { label: "Wallet", id: "user.wallet", cols: 3, short: true },
  {
    label: `Number of spins`,
    id: "spins",
    cols: 3,
    short: false,
  },
  {
    label: `Total ${process.env.REACT_APP_TOKEN_SIGN || ""} burned`,
    id: "burnedTokens",
    cols: 2,
    short: false,
  },
];
export default function Leaderboard() {
  const { leaderboard } = React.useContext(StoreContext);

  const cols = columns.map((col) => col.cols).reduce((acc, col) => acc + col);

  return (
    <Container>
      <div>
        <LineItems columns={columns} items={leaderboard} cols={cols} />
      </div>
    </Container>
  );
}
