// eslint-disable-next-line no-unused-vars
import React, { useEffect } from "react";
import {
  Popper,
  Paper,
  Center,
  Group,
  Text,
  useMantineTheme,
} from "@mantine/core";

function ConnectWalletPopper({
  connectWalletWarningVisible,
  warningReferenceElement,
}) {
  const theme = useMantineTheme();

  return (
    <Group position="center">
      <Popper
        arrowSize={5}
        position="bottom"
        arrowStyle={{
          backgroundColor: theme.colors.gray[1],
        }}
        withArrow
        mounted={connectWalletWarningVisible}
        referenceElement={warningReferenceElement}
        transition="pop-top-left"
        transitionDuration={200}
      >
        <Paper
          style={{
            backgroundColor: theme.colors.gray[1],
          }}
        >
          <Center style={{ height: 100, width: 200 }}>
            <Text weight="800" color="red" className="leading-normal m-2">
              please connect your wallet to view your spin details...
            </Text>
          </Center>
        </Paper>
      </Popper>
    </Group>
  );
}

export default ConnectWalletPopper;
